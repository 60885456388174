import React from 'react';
import './Typography.scss';

export default function Paragraph(props) {
	const { text, color, textAlign } = props;
	var text_array = text.split('<br>')
	return (
		<>
			{text_array.map((v, i)=>
				(<p className='typography-paragraph' key={i} style={{ color, textAlign }}>
					{v}
				</p>)
			)}
		</>
		
	);
}
